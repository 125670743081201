// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

var subsiteHijackMainNav = ['bdes'];
var subsiteHijackMainLogoClickDestination = ['bdes'];
var subsiteTitleNotClickable = false;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  $(".shareWrapper").prependTo($(".postAside.postFeedWrapper"));

  // If on BDES subsite home page then...
  if ($('body.subsite[class*="bdes"]').length) {
    $("<div class='carouselDetailWrapper'>").insertBefore($(".carouselSubsite .carouselSlideTitle"));
    $("<div class='carouselSlideDetail'>").prependTo($(".carouselSubsite .carouselDetailWrapper"));
    $(".carouselSubsite .carouselSlideDetail").append($(".subsiteBody").contents());
    $(".homeFeed.SubsitehomeintroFeed").insertAfter($(".headerTextSubsite"));
  }

  // If on main site home page then wrap feeds 1 and 2 in wrapper
  if (!$('body.homepage').hasClass('subsite')) {
    $('.homeFeedBox1,.homeFeedBox2').wrapAll('<div class="homeFeedBox1And2Wrapper"></div>');
  }

});